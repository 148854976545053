import sendRequest from "../../../app/utils/send-request-helper";
import uploadImage from "../../../app/utils/upload-image-helper";

export const updateContent = async (updatedContent, contentId) => {
  if (!contentId || contentId === "") {
    return;
  }
  let imageUrl = "";
  // eslint-disable-next-line no-unused-vars
  const { image, ...requestBody } = updatedContent;
  if (updatedContent.image) {
    imageUrl = await uploadImage(updatedContent.image);
    requestBody.image_url = imageUrl;
  }
  const data = await sendRequest({
    url: `${process.env.VUE_APP_URL}/resources/contents/${contentId}`,
    method: "PUT",
    isAuth: true,
    body: requestBody,
  });
  return data;
};
