<template>
  <layout>
    <template #header>
      <app-header
        color="white"
        :showMobileMenuButton="false"
        :showMobileLogo="false"
      >
        <template #left>
          <div>
            <router-link to="/system-admin/contents">
              <outline-button color="#777A7A">Cancel</outline-button>
            </router-link>
            <outline-button
              class="ml-3"
              color="inqliRed"
              @click="openContentDeleteDialog"
              >Delete</outline-button
            >
          </div>
        </template>
        <template #right>
          <div class="right-button-list">
            <outline-button class="mx-3" @click="triggerPreviewContent"
              >Preview</outline-button
            >
            <inqli-button @click="update">Update</inqli-button>
          </div>
        </template>
      </app-header>
    </template>
    <h1 class="my-5">Edit content</h1>
    <v-card class="container" rounded="xl" v-if="content">
      <content-form
        @submit="submit"
        @preview="previewContent"
        :content="content"
      />
    </v-card>
    <content-preview
      v-model="showPreview"
      :content="updatedContent"
      :author="author"
      @publish="update"
      publishLabel="Update"
    />
    <content-delete-dialog
      v-if="content"
      v-model="showContentDeleteDialog"
      :content-id="content.id"
    />
  </layout>
</template>
<script>
import Header from "../../../app/components/headers/DefaultHeader";
import DefaultLayout from "../../../app/components/layouts/DefaultLayout";
import Button from "../../../app/components/buttons/Button";
import OutlineButton from "../../../app/components/buttons/OutlineButton";
import EventBus from "../event-bus";
import sendRequest from "../../../app/utils/send-request-helper";
import { updateContent } from "./content-edit";

import ContentForm from "../components/ContentForm";
import ContentPreview from "../components/ContentPreview";
import ContentDeleteDialog from "../components/content-delete-dialog/ContentDeleteDialog.vue";

export default {
  name: "ContentEdit",
  metaInfo: {
    title: "Edit content",
  },
  components: {
    "app-header": Header,
    layout: DefaultLayout,
    "content-form": ContentForm,
    "inqli-button": Button,
    "outline-button": OutlineButton,
    "content-preview": ContentPreview,
    "content-delete-dialog": ContentDeleteDialog,
  },
  data() {
    return {
      content: null,
      author: null,
      updatedContent: null,
      showPreview: false,
      showContentDeleteDialog: false,
    };
  },
  async mounted() {
    try {
      const res = await sendRequest({
        url: `/resources/contents/${this.$route.params.id}`,
        method: "GET",
        isAuth: true,
      });
      this.content = res.content;
      this.author = res.author;
    } catch (err) {
      this.$store.dispatch(
        "callAlert",
        "An error occurred while getting content."
      );
    }
    this.loading = false;
  },
  methods: {
    update() {
      EventBus.$emit("submit");
    },
    triggerPreviewContent() {
      EventBus.$emit("preview");
    },
    openContentDeleteDialog() {
      this.showContentDeleteDialog = true;
    },
    async submit(content) {
      try {
        const updatedContent = await updateContent(content, this.content.id);
        if (updatedContent) {
          this.$router.push("/contents/" + this.content.id);
        }
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while updating content"
        );
      }
    },
    previewContent(content) {
      this.showPreview = true;
      this.updatedContent = content;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 15px 48px;
  width: 75%;
  max-width: 814px;
  margin: 10px auto;
}
.button-list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.right-button-list {
  display: flex;
}
@media (max-width: $breakpoint-tablet-large) {
  .container {
    padding: 10px 30px;
    width: 95%;
  }
}
@media (max-width: $breakpoint-mobile) {
  .container {
    padding: 10px 15px;
    width: 95%;
  }
}
</style>
