<template>
  <confirm-dialog
    v-model="displayDialog"
    :title="dialogTitle"
    :buttonList="dialogButtonList"
    :description="dialogDescription"
    :dialogKey="contentId"
  />
</template>
<script>
import { deleteContent } from "./content-delete-dialog";
import ConfirmDialog from "../../../../app/components/dialogs/ConfirmDialog.vue";
export default {
  name: "ContentDeleteDialog",
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  data() {
    return {
      dialogTitle: "Delete content",
      dialogDescription: "This action can not be undone.",
      dialogButtonList: [
        {
          label: "Delete anyway",
          color: "inqliGreen",
          argument: this.contentId,
          onClick: this.deleteContentHandler,
        },
        {
          label: "Cancel",
          color: "inqliRed",
          argument: null,
          onClick: () => (this.displayDialog = false),
        },
      ],
    };
  },
  props: {
    value: {
      type: Boolean,
      require: true,
    },
    contentId: {
      type: String,
      require: true,
    },
  },
  computed: {
    displayDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async deleteContentHandler() {
      try {
        this.displayDialog = false;
        await deleteContent(this.contentId);
        this.$router.push("/system-admin/contents");
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while deleting content."
        );
      }
    },
  },
};
</script>
