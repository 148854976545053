var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("app-header", {
                attrs: {
                  color: "white",
                  showMobileMenuButton: false,
                  showMobileLogo: false
                },
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/system-admin/contents" } },
                              [
                                _c(
                                  "outline-button",
                                  { attrs: { color: "#777A7A" } },
                                  [_vm._v("Cancel")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "outline-button",
                              {
                                staticClass: "ml-3",
                                attrs: { color: "inqliRed" },
                                on: { click: _vm.openContentDeleteDialog }
                              },
                              [_vm._v("Delete")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "right",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "right-button-list" },
                          [
                            _c(
                              "outline-button",
                              {
                                staticClass: "mx-3",
                                on: { click: _vm.triggerPreviewContent }
                              },
                              [_vm._v("Preview")]
                            ),
                            _c("inqli-button", { on: { click: _vm.update } }, [
                              _vm._v("Update")
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h1", { staticClass: "my-5" }, [_vm._v("Edit content")]),
      _vm.content
        ? _c(
            "v-card",
            { staticClass: "container", attrs: { rounded: "xl" } },
            [
              _c("content-form", {
                attrs: { content: _vm.content },
                on: { submit: _vm.submit, preview: _vm.previewContent }
              })
            ],
            1
          )
        : _vm._e(),
      _c("content-preview", {
        attrs: {
          content: _vm.updatedContent,
          author: _vm.author,
          publishLabel: "Update"
        },
        on: { publish: _vm.update },
        model: {
          value: _vm.showPreview,
          callback: function($$v) {
            _vm.showPreview = $$v
          },
          expression: "showPreview"
        }
      }),
      _vm.content
        ? _c("content-delete-dialog", {
            attrs: { "content-id": _vm.content.id },
            model: {
              value: _vm.showContentDeleteDialog,
              callback: function($$v) {
                _vm.showContentDeleteDialog = $$v
              },
              expression: "showContentDeleteDialog"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }