import sendRequest from "../../../../app/utils/send-request-helper";

export const deleteContent = async (contentId) => {
  if (!contentId || contentId === "") {
    return;
  }
  await sendRequest({
    url: `${process.env.VUE_APP_URL}/resources/contents/${contentId}`,
    method: "DELETE",
    isAuth: true,
  });
};
