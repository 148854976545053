var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("confirm-dialog", {
    attrs: {
      title: _vm.dialogTitle,
      buttonList: _vm.dialogButtonList,
      description: _vm.dialogDescription,
      dialogKey: _vm.contentId
    },
    model: {
      value: _vm.displayDialog,
      callback: function($$v) {
        _vm.displayDialog = $$v
      },
      expression: "displayDialog"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }